import React, { useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51P6WTREdczHiP2Vkclln6RIZuQ2XmR67PodJ8oAL6a4WKpt82W8fpLZbXoZVCv6HBsifTn3liBZEI7c44bFBcWky00CNeN3o5s');
// const apiHost = 'http://localhost:3001';
const apiHost = 'https://api.signaturesque.com';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

function PaymentForm({ onPaymentSuccess, imageId }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const { data: { clientSecret } } = await axios.post(apiHost+'/create-payment-intent', { imageId });

    const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (stripeError) {
      setError(stripeError.message);
    } else if (paymentIntent.status === 'succeeded') {
      onPaymentSuccess(imageId);
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.cardElementContainer}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <button type="submit" disabled={!stripe || processing} style={styles.payButton}>
        {processing ? 'Processing...' : 'Pay €2 and Download'}
      </button>
      {error && <div style={styles.errorMessage}>{error}</div>}
    </form>
  );
}

function ImageUpload() {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState('');
  const [processedImages, setProcessedImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [imageId, setImageId] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async () => {
    if (!image) {
      setMessage('Please upload or capture an image first.');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('originalPic', image);

    try {
      const response = await axios.post(apiHost+'/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('Image processed successfully!');
      setProcessedImages(response.data.composites);
      setImageId(response.data.imageId);
      setShowPayment(true);
    } catch (error) {
      setMessage('Error processing image: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = (imageId) => {
    window.location.href = `${apiHost}/download/${imageId}`;
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Image Upload and Processing</h1>
      
      <div style={styles.uploadContainer}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={styles.fileInput}
          id="fileInput"
        />
        <label htmlFor="fileInput" style={styles.fileInputLabel}>
          Choose File
        </label>
        <input
          type="file"
          accept="image/*"
          capture="camera"
          onChange={handleImageChange}
          style={styles.fileInput}
          id="cameraInput"
        />
        <label htmlFor="cameraInput" style={styles.fileInputLabel}>
          Take Photo
        </label>
      </div>

      {preview && (
        <div style={styles.previewContainer}>
          <h3 style={styles.subtitle}>Preview</h3>
          <img src={preview} alt="Preview" style={styles.previewImage} />
        </div>
      )}

      <button onClick={handleSubmit} style={styles.button} disabled={isLoading}>
        {isLoading ? 'Processing...' : 'Process Image'}
      </button>

      {message && <p style={styles.message}>{message}</p>}

      {processedImages && (
        <div style={styles.resultContainer}>
          <h3 style={styles.subtitle}>Processed Images</h3>
          <div style={styles.imageGrid}>
            {processedImages.map((composite, index) => (
              <div key={index}>
                <h4 style={styles.imageTitle}>Composite {index + 1}</h4>
                <img 
                  src={`${apiHost}/review-dir/${composite}`} 
                  alt={`Composite ${index + 1}`} 
                  style={styles.resultImage}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {showPayment && (
        <div style={styles.paymentContainer}>
          <h3 style={styles.subtitle}>Download High Resolution Image</h3>
          <Elements stripe={stripePromise}>
            <PaymentForm onPaymentSuccess={handlePaymentSuccess} imageId={imageId} />
          </Elements>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: '0 auto',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  uploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  fileInput: {
    display: 'none',
  },
  fileInputLabel: {
    padding: '10px 15px',
    backgroundColor: '#4CAF50',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
    fontSize: '14px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#2196F3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  previewContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '300px',
    borderRadius: '4px',
  },
  resultContainer: {
    width: '100%',
    marginTop: '20px',
  },
  subtitle: {
    fontSize: '18px',
    marginBottom: '10px',
    color: '#444',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
  },
  imageTitle: {
    fontSize: '16px',
    marginBottom: '5px',
    color: '#555',
  },
  resultImage: {
    width: '100%',
    borderRadius: '4px',
  },
  message: {
    marginTop: '10px',
    color: '#666',
  },
  paymentContainer: {
    marginTop: '20px',
    width: '100%',
    maxWidth: '400px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardElementContainer: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  payButton: {
    padding: '10px 20px',
    backgroundColor: '#5469d4',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.2s ease',
  },
  errorMessage: {
    color: '#fa755a',
    marginTop: '10px',
    textAlign: 'center',
  },
};

export default ImageUpload;